import request from 'iebdc/utils/request';
import { REST_SERVICE } from 'iebdc/config';

const hall = {
  //查询办事大厅列表
  query(params) {
    return request({
      url: REST_SERVICE.hall.query,
      method: 'get',
      params,
    });
  },
  //通过办事大厅id查询办事大厅列表
  queryById(params) {
    return request({
      url: REST_SERVICE.hall.queryById,
      method: 'get',
      params,
    });
  },
  //查询流程定义分类列表
  getList(params) {
    return request({
      url: REST_SERVICE.define.getList,
      method: 'get',
      params,
    });
  },
  // 增加编辑
  addHall(data) {
    return request({
      url: REST_SERVICE.hall.addHall,
      method: 'post',
      data,
    });
  },
  //删除办事大厅
  deleteHall(data) {
    return request({
      url: REST_SERVICE.hall.deleteHall,
      method: 'post',
      data,
    });
  },
  //获取时间段列表
  getTimeList(params) {
    return request({
      url: REST_SERVICE.hall.getTimeList,
      method: 'get',
      params,
    });
  },
  //保存时间段
  addTimeList(data) {
    return request({
      url: REST_SERVICE.hall.addTimeList,
      method: 'post',
      data,
    });
  },
  //删除时间段
  deleteTimeList(params) {
    return request({
      url: REST_SERVICE.hall.deleteTimeList,
      method: 'get',
      params,
    });
  },
  //增加窗口
  windowConfig(data) {
    return request({
      url: REST_SERVICE.hall.windowConfig,
      method: 'post',
      data,
    });
  },
  //删除窗口管理数据
  deleteWindow(data) {
    return request({
      url: REST_SERVICE.hall.deleteWindow,
      method: 'post',
      data,
    });
  },
  //绑定流程
  configSubFlowList(data) {
    return request({
      url: REST_SERVICE.hall.configSubFlowList,
      method: 'post',
      data,
    });
  },
  //在大厅根据预约事项设置每个预约大类对应的数量
  updateWorkOfficeYyconfig(data) {
    return request({
      url: REST_SERVICE.hall.updateWorkOfficeYyconfig,
      method: 'post',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      data,
    });
  },
  //更新内外网数据推送时间
  waitTimeConfig(data) {
    return request({
      url: REST_SERVICE.hall.waitTimeConfig,
      method: 'post',
      data,
    });
  },
};
export default hall;
